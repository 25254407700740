import React from "react";
// import { Link } from "react-router-dom";
// import { elastic as Menu } from "react-burger-menu";

function Header(props) {
  return (
    <div className="header-part">
      <div className="head-icon"></div>
      <div className="head-txt">
        <ul className="">
          <li className="head-txt-cnt">
            関東＆関西エリア／宮城／愛知・岐阜／福岡・熊本・佐賀・長崎エリア全域対応
            <br />
            <span className="sp-none">
              不用品回収、粗大ごみ回収、遺品整理、ゴミ屋敷
            </span>
          </li>
        </ul>
      </div>
      {/* <div className="menu-bar">
        <Menu right className="menu-bar">
          <a href="https://porte-co.jp" className="menu-item">
            ポルテのTOPページ
          </a>
          <a href="https://porte-co.jp/service" className="menu-item">
            サービス
          </a>
          <a href="https://porte-co.jp/price" className="menu-item">
            料金プラン
          </a>
          <a href="https://porte-co.jp/area" className="menu-item">
            埼玉県対応エリア
          </a>
          <a href="https://porte-co.jp/about" className="menu-item">
            会社概要
          </a>
          <a href="https://form.porte-co.jp" className="menu-item">
            無料見積もり
          </a>
        </Menu>
      </div> */}
      {/* <div className="head-menu-mx">
        <ul className="head-menu-ul">
          <a href="https://porte-co.jp/price">
            <li className="head-menu-plan">料金プラン</li>
          </a>
          <a href="https://porte-co.jp/area">
            <li className="head-menu-plan">対応エリア</li>
          </a>
          <a href="https://porte-co.jp/about">
            <li className="head-menu-plan">会社概要</li>
          </a>
          <a href="https://form.porte-co.jp">
            <li className="head-menu-form">無料見積もり</li>
          </a>
        </ul>
      </div> */}
    </div>
  );
}

export default Header;
