import React from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";

export default function Thanks() {
  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: `<script async>
ytag({
  "type": "yss_conversion",
  "config": {
    "yahoo_conversion_id": "1001275382",
    "yahoo_conversion_label": "nTV-CK72hpEYEILE0J4p",
    "yahoo_conversion_value": "1000"
  }
});
</script>`,
        }}
      ></div>
      <Header />
      <div className="thanks-bg">
        <div className="thank-txt"></div>
        <div className="poru-illu"></div>
        <div className="top-link">
          <a
            href="https://porte-co.jp/"
            target="_bl
        "
            rel="topページ"
          >
            ポルテ TOPへ
          </a>
        </div>
      </div>
    </div>
  );
}
